import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
import InternalImage from '../../components/InternalImage';
import HackavizFooter from '../../components/HackavizFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageBanner styleId={1} title={'Hackaviz 2022'} description={'La cinquième édition du Hackaviz revient en mars !'} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <p>{`L’association Toulouse Dataviz (TDV) organise son HACKAVIZ, un concours de visualisation de données en temps limité, seul ou en équipe, doté de récompenses, ouvert à un très large public.`}</p>
        <InternalImage name={'hackaviz2022'} altText={''} mdxType="InternalImage" />
        <h1>{`LE PRINCIPE`}</h1>
        <p>{`Raconter une histoire avec des graphiques, à partir d’un jeu de données inédit, seul ou en équipe.
A partir du moment ou les données sont mises à disposition, les candidats ont 10 jours pour rendre leur création.`}</p>
        <ul>
          <li parentName="ul">{`Date de mise à disposition des données : `}<strong parentName="li">{`24 mars 2022`}</strong></li>
          <li parentName="ul">{`Date limite de remise des contributions : `}<strong parentName="li">{`3 avril 2022`}</strong></li>
          <li parentName="ul">{`Hackaviz Party : Présentation des réalisations et remise des prix : `}<strong parentName="li">{`7 avril 2022`}</strong></li>
        </ul>
        {/*
         <div align="center">
          <Button link={'https://tally.so/r/mePdQn'} text={"Je m'inscris"} />
         </div>
         */}
        <h1>{`PRÉPARER L’HACKAVIZ`}</h1>
        <p>{`Voici des newsletters pour vous préparer à l'Hackaviz. Elles ont été écrites pour l'Hackaviz précédent mais elles restent toujours d'actualité.`}</p>
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-13--spcial-hackaviz-2021'} text={'#13 - Spécial Hackaviz 1/4'} mdxType="Button" />
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-14--spcial-hackaviz-2021'} text={'#14 - Spécial Hackaviz 2/4'} mdxType="Button" />
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-15--spcial-hackaviz-2021'} text={'#15 - Spécial Hackaviz 3/4'} mdxType="Button" />
        <Button link={'https://newsletters.toulouse-dataviz.fr/newsletter--toulouse-dataviz-16--spcial-hackaviz-2021'} text={'#16 - Spécial Hackaviz 4/4'} mdxType="Button" />
        <h1>{`LE JEU DE DONNÉES`}</h1>
        <p>{`Cette année, nous proposons un jeu de données inédit issu du site
`}<a parentName="p" {...{
            "href": "http://aidescreation.org/consultation-aides_culturelles_versees_base_actions_soutenues-1.html"
          }}>{`AidesCreation.org`}</a>{`.`}</p>
        <p>{`C'est une base de données des financements collectés et
distribués par les `}<a parentName="p" {...{
            "href": "https://fr.wikipedia.org/wiki/Soci%C3%A9t%C3%A9_de_gestion_des_droits_d%27auteur"
          }}>{`organismes de gestion collective des droits
d’auteur`}</a>{`,
au titre de la rémunération pour `}<a parentName="p" {...{
            "href": "https://fr.wikipedia.org/wiki/Copie_priv%C3%A9e#France"
          }}>{`copie
privée`}</a>{`.`}</p>
        <Button link={'/hackaviz/2022-data'} type={'internal'} text={'Télécharger les données'} mdxType="Button" />
        <h1>{`LES RÈGLES, L'ÉVALUATION et L'HACKAVIZ PARTY`}</h1>
        <Button link={'/hackaviz/reglement'} type={'internal'} text={'Voir le détail'} mdxType="Button" />
        <HackavizFooter year={'2022'} mdxType="HackavizFooter" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      